.filter-container {
    border: 1px solid #dadada;
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
}

.filter-select {
    width: 100%;
    max-width: 350px;
    background-color: white;
    max-height: 100px;
}

.table-container {
    overflow-x: auto;
}

.table-wrapper {
    width: 100%;
    border-collapse: collapse;
    border: 1px solid #ccc;
}